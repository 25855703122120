import React from "react"
import { kebabCase } from 'lodash'

import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import BlogArchiveRowWithTag from '../components/BlogArchiveRowWithTag'
import ArticlesRowWithTag from '../components/ArticlesRowWithTag'

import Layout from '../components/Layout'
import Seo from '../components/Seo'

const Tags = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const { edges: posts, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} gearchiveerde post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout pageData={data.strapiBlog} location={location}>
      <Seo
        title={tag}
        description="Gefilterde labels"
      />

      <nav className="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/tags">Tags</Link></li>
          <li className="is-active">
            <Link to=''>{tag}</Link>
          </li>
        </ul>
      </nav>

      <div className="container content">
        <ArticlesRowWithTag data={data} tag={tag} />
        <BlogArchiveRowWithTag data={data} tag={tag} />
      </div>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiBlog {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date(formatString: "D MMMM YYYY")
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 328
                  height: 244
                  transformOptions: {grayscale: true}
                )
              }
            }
          }
        }
      }
    }
    allStrapiArticle(
      limit: 2000
      sort: {fields: created_at, order: DESC}
      
      filter: {tags: {elemMatch: {name: {in: [$tag] }}}}
    ) {
      totalCount
      edges {
        node {
          slug
          title
          sticky
          tags {
            name
          }
          content
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {grayscale: true}
                )
              }
            }
          }
          images {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {grayscale: true}
                )
              }
            }
          }
        }
      }
    }
  }
`
