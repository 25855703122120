import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ArticleLink from './ArticleLink'

const ArticlesRowWithTag = ({ data, tag }) => {
  const { edges: articles, totalCount } = data.allStrapiArticle
  const tagHeader = `${totalCount} recente post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return(
    <div className="container content">
      <h1 className="title">{tagHeader}</h1>
      <div className="columns is-multiline">
        {articles.map((article, i) =>
          <div className="column is-4" key={i}>
            <ArticleLink article={article}  />
          </div>
        )}
      </div>
    </div>
  )

}

export default ArticlesRowWithTag
