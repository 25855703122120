import React from 'react'
import { kebabCase } from 'lodash'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"


const BlogArchiveRowWithTag = ({ data, tag }) => {
  const { edges: posts, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} gearchiveerde post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`
  
  return (
    <div className="container content">
      <h1 className="title">{tagHeader}</h1>
      <div className="columns is-multiline">
        {posts.map(({ node: post }) => {
          const tags = post.frontmatter.tags
          return (
            <div className="column is-one-third" key={post.id}>

              <div
                className={`card ${
                post.frontmatter.featuredImage ? '' : 'has-background-grey-lighter'
              }`}
              >
                {post.frontmatter.featuredImage ? (
                  <div className="card-image">
                    <GatsbyImage
                      className="image"
                      image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
                      alt={post.frontmatter.title}
                    />
                  </div>
                ) : null}

                <div className="card-content">
                  <div className="media">
                    <div className="media-content">
                      <Link to={post.fields.slug} className="title is-4">
                        {post.frontmatter.title}
                      </Link>
                    </div>
                  </div>
                  <div className="content">
                    {post.excerpt}
                  </div>
                  <div className="content">
                    {tags ? (
                      <div className="tags">
                        {tags.map(tag => (
                          <Link
                            to={`/tags/${kebabCase(tag)}/`}
                            key={tag}
                            className="tag is-link"
                          ><span>{tag}</span>
                          </Link>
                        ))}
                      </div>
                    ) : null}
                  </div>
                </div>

                <footer className="card-footer">
                  <span className="card-footer-item">{post.frontmatter.date}</span>
                  <Link className="card-footer-item" to={post.fields.slug}>
                    Lees verder
                  </Link>
                </footer>

              </div>
            </div>
          )
        })}

      </div>
    </div>
  )
}

export default BlogArchiveRowWithTag
